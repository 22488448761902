import { AlertStyles } from './styles'

interface Props {
  error: string
  hasTopMargin?: boolean
}

export const ErrorMessage = ({ error, hasTopMargin = false }: Props) => (
  <AlertStyles.Error hasTopMargin={hasTopMargin}>{error}</AlertStyles.Error>
)
