import { useMemo } from 'react'

import { AlertStyles } from 'src/components/Display/Alerts/styles'
import { useCouponContext } from 'src/providers/CouponProvider/CouponContext'

interface Props {
  alertColor?: string
  hasTopMargin?: boolean
}

export const CouponFeedbackSuccess = ({
  alertColor = 'var(--persianBlue)',
  hasTopMargin = false,
}: Props) => {
  const { coupon, isValid } = useCouponContext()

  const message = useMemo(() => {
    return coupon?.metadata?.checkoutMessage
      ? coupon.metadata.checkoutMessage
      : `${coupon?.id} has been applied successfully!`
  }, [coupon?.id, coupon?.metadata?.checkoutMessage])

  return isValid ? (
    <AlertStyles.Success
      alertColor={alertColor}
      hasTopMargin={hasTopMargin}
      data-testid='coupon-feedback-success'
    >
      {message}
    </AlertStyles.Success>
  ) : null
}
