export const Mask = ({ isBusy, className, children }) => (
  <Styles.Container isBusy={isBusy} className={className}>
    {children}
  </Styles.Container>
)

const Styles = {
  Container: styled.div`
    position: relative;
    ${({ isBusy }) =>
      isBusy &&
      css`
        ::after {
          background: white;
          bottom: 0;
          content: '';
          left: 0;
          opacity: 0.5;
          position: absolute;
          right: 0;
          top: -16px;
        }
      `}
  `,
}
