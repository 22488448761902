import { button } from 'src/styles'
import { ErrorMessage, SuccessMessage } from './Alerts'
import { Input } from 'src/components/Input'
import { Mask } from 'src/components/Display/Mask'
import { RichText } from 'src/components/Display/RichText'
import { UserAction } from 'src/components/UserAction'
import { useUsernameAvailability } from 'src/hooks/useUsernameAvailability'

export const Form = {
  Form: ({ children, isBusy, className = '' }) => (
    <Mask isBusy={isBusy} className={className}>
      {children}
    </Mask>
  ),

  Header: ({ children }) => <Styles.Header light>{children}</Styles.Header>,

  Notification: ({ isError = false, isSuccess = false, message }) =>
    message ? (
      <React.Fragment>
        {isError && <ErrorMessage error={message?.toString()} />}
        {isSuccess && <SuccessMessage message={message?.toString()} />}
      </React.Fragment>
    ) : null,

  UsernameInput: ({ className, error, initialValue = null, placeholder, setValue, value = '' }) => {
    const { isAvailable } = useUsernameAvailability(value, initialValue)

    return (
      <Input
        className={className}
        {...(!isAvailable && { error: error })}
        label='Username'
        onChange={(e) => setValue(e?.target?.value)}
        placeholder={placeholder}
        prefix='@'
        usernameIsAvailable={isAvailable}
        value={value}
      />
    )
  },

  SocialInput: ({ className, error, label, onChange, placeholder, value = '' }) => {
    return (
      <Input
        className={className}
        error={error}
        label={label}
        onChange={onChange}
        placeholder={placeholder}
        prefix='@'
        value={value}
      />
    )
  },

  Submit: ({
    action = obe.events.user_action,
    buttonStyle = 'newPrimary',
    className = undefined,
    isBusy,
    isDisabled = false,
    label = 'Submit',
    onClick = Function.prototype,
    tracking = {},
    disableTracking = false,
  }) =>
    React.createElement(
      Styles.Button,
      {
        action,
        buttonStyle,
        className,
        isDisabled: isBusy || isDisabled,
        onClick: !isDisabled ? onClick : Function.prototype,
        tracking,
        disableTracking,
      },
      isBusy ? <Styles.Spinner /> : label,
    ),
}

const Styles = {
  Button: styled(UserAction)`
    ${({ buttonStyle }) => css`
      ${button[buttonStyle]}
    `}
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        :focus {
          box-shadow: none;
        }
      `}
  `,
  Header: styled(RichText.Medium)`
    h2 {
      margin-top: 0;
    }
    em {
      color: red;
      font-style: normal;
      text-decoration: line-through;
    }
    margin-bottom: 1.5rem;
  `,
  Spinner: styled.div`
    @keyframes spinner-border {
      to {
        transform: rotate(360deg);
      }
    }

    animation: 0.75s linear infinite spinner-border;
    border: 0.25em solid currentColor;
    border-radius: 50%;
    border-right-color: transparent;
    display: inline-block;
    vertical-align: text-bottom;
    border-width: 0.2em;
    height: 1rem;
    opacity: 0.5;
    width: 1rem;
  `,
}
