import type { SVGProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const Checkmark = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={twMerge('h-3.5 w-3.5 fill-primary-400 stroke-primary-400 stroke-[0.4px]', className)}
    {...props}
  >
    <path d='M11.1529 2.76127L11.1594 2.75481C11.5022 2.4151 12.0557 2.41502 12.3983 2.75487L12.3996 2.7562L12.3996 2.75621C12.7396 3.09997 12.7414 3.6541 12.4054 4.00044L4.92729 12.8267L4.92097 12.8341L4.91395 12.8409C4.7428 13.007 4.52447 13.0968 4.29611 13.0968C4.0703 13.0968 3.84361 13.0087 3.67525 12.8379L11.1529 2.76127ZM11.1529 2.76127L11.147 2.76829L4.28405 10.9473L2.14791 8.78523C1.80512 8.43826 1.24904 8.43829 0.906203 8.78521L0.906084 8.78533C0.564824 9.13124 0.564681 9.68954 0.906183 10.0352L0.906185 10.0352L3.67504 12.8377L11.1529 2.76127Z' />
  </svg>
)
