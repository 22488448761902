import { breakpoint, mixin } from 'src/styles'
import { Image } from 'src/components/Display/Image'

export const Render = {
  Event: {
    OuterContainer: styled.div`
      border-bottom: 1px solid #dee2e6;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      position: relative;

      ${({ isProfileSchedule }) =>
        isProfileSchedule &&
        css`
          ${breakpoint.largeAndUp} {
            padding: 1rem 0.5rem 1rem 0.5rem;
          }
        `}

      ${breakpoint.smallAndDown} {
        padding: 1rem 0.7rem 1rem 0.7rem;
      }
    `,
    Container: styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 300;
    `,
    Avatar: styled(Image)`
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0 1rem 0 0.5rem;
      width: 48px;
    `,
    Details: styled.div`
      flex-grow: 1;
      margin-top: 5px;
      padding-right: 0.7rem;
    `,
    Title: styled.div`
      font-size: ${({ isCompact }) => (isCompact ? '14' : '16')}px;
      line-height: 20px;
      letter-spacing: 0.5px;
    `,
    Instructors: styled.div`
      ${mixin.spaceChildrenVertical('.3rem')}
      margin-top: .4rem;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.85px;
      text-transform: uppercase;

      > a {
        display: block;
      }
    `,
    HighlightContainer: styled.div`
      ${mixin.spaceChildrenVertical('.3rem')}
      margin-top: .6rem;
    `,
    InnerDetailsTwo: styled.div`
      ${mixin.spaceChildrenVertical('.4rem')}
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: lowercase;
      margin-top: 0.6rem;

      ${breakpoint.smallAndDown} {
        margin-top: 1rem;
      }
    `,
    InnerDetailsThree: styled.div`
      ${mixin.flexCenterHorizontal}
      position: absolute;
      top: 63px;
      right: 12px;
      flex-wrap: wrap;
      font-size: 20px;
      line-height: 24px;
      font-weight: 200;
      letter-spacing: 0.015em;
      color: #ff0000;
      text-transform: uppercase;
    `,
    Play: styled.div`
      display: none;
    `,
  },
}
