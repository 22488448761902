import type { Moment } from 'moment'

import { client } from 'src/api'
import type { AvailableFilterCountsType, FacetType, QuickFilterType } from 'src/types/Filters'
import type { ObeClassType } from 'src/types/ObeClass'

export const getRawObeClassById = (id: string) =>
  client.get<ObeClassType>(`/api/v4/obe_classes/${id}`)

export const getObeClassById = async (id: string) => {
  const { data } = await client.get<ObeClassType>(`/api/v4/obe_classes/${id}`)

  const obeClass = { ...data, instructorNames: [] } as ObeClassType & { instructorNames: string[] }
  if (obeClass.instructors) {
    for (const instructor of obeClass.instructors) {
      obeClass.instructorNames.push(instructor.name.trim())
    }
  }

  return obeClass
}

export const addObeClassToCalendar = (id: string, rsvpFromContext?: string, startTime?: Moment) =>
  client.post(`/api/v4/obe_classes/${id}/add_to_calendar`, {
    rsvpFromContext,
    startTime,
  })

export const removeObeClassFromCalendar = (id: string) =>
  client.post(`/api/v4/obe_classes/${id}/remove_from_calendar`)

export const getObeClasses = ({
  page = 1,
  perPage = 24,
  selectedExclusionFilters = undefined,
  selectedFilters = undefined,
  search = undefined,
  sort = undefined,
}: {
  page?: number
  perPage?: number
  selectedExclusionFilters?: {
    popularMusicOnly?: boolean
    hideCompletedClasses?: boolean
  }
  selectedFilters?: Record<string, string>
  search?: string
  sort?: string
}) => {
  return client.post<{ obeClasses: ObeClassType[] }>('/api/v4/obe_classes', {
    page,
    per_page: perPage,
    popular_music_only: selectedExclusionFilters?.popularMusicOnly ?? false,
    hide_completed_classes: selectedExclusionFilters?.hideCompletedClasses ?? false,
    selected_filters: selectedFilters,
    ...(search ? { search } : {}),
    ...(sort ? { sort } : {}),
  })
}

export const getAvailableFilters = () =>
  client.post<FacetType[]>('/api/v4/obe_classes/available_filters')

export const getMyFilters = async () => {
  const { data } = await client.get<QuickFilterType>('/api/v4/members/my_filters')
  return data
}

export const getAvailableFilterCounts = ({
  selectedExclusionFilters = undefined,
  selectedFilters = undefined,
  search = '',
}: {
  selectedExclusionFilters?: {
    popularMusicOnly?: boolean
    hideCompletedClasses?: boolean
  }
  selectedFilters?: Record<string, string>
  search?: string
}) =>
  client.post<{ availableFilterCounts: AvailableFilterCountsType }>(
    '/api/v4/obe_classes/available_filter_counts',
    {
      popular_music_only: selectedExclusionFilters?.popularMusicOnly,
      hide_completed_classes: selectedExclusionFilters?.hideCompletedClasses,
      selected_filters: selectedFilters,
      ...(search ? { search } : {}),
    },
  )
