import { CouponFeedbackSuccess, ErrorMessage } from 'src/components/Display/Alerts'
import { CouponFeedbackError } from './CouponFeedbackError'

interface Props {
  error?: string
  hideCouponFeedback?: boolean
}

export const SubscriptionAlerts = ({ error, hideCouponFeedback = false }: Props) => {
  return (
    <>
      {!hideCouponFeedback && (
        <>
          <CouponFeedbackSuccess alertColor='var(--green)' />
          <CouponFeedbackError />
        </>
      )}
      {error ? <ErrorMessage error={error} /> : null}
    </>
  )
}
