import PropTypes from 'prop-types'
import { getWeekStart, getWeekData } from 'src/utils/Date'
import { Icon } from 'src/components/Icon'

const defaultHandler = () => null

export const DatePicker = ({ day, onDayClick = defaultHandler, onWeekChange = defaultHandler }) => {
  const startOfWeek = React.useMemo(() => getWeekStart(day), [day])
  const currentSelectedWeek = React.useMemo(() => getWeekData(startOfWeek), [startOfWeek])

  if (!currentSelectedWeek) {
    return null
  }

  return (
    <Styles.Container>
      <Styles.ChevronContainer
        role='button'
        left
        onClick={() => onWeekChange(currentSelectedWeek.prev)}
      >
        <Styles.Chevron left />
      </Styles.ChevronContainer>
      {currentSelectedWeek.days.map(({ isoDate, name, dayOfMonth, isCurrent }) => (
        <Styles.Day
          key={`day-${isoDate}`}
          role='button'
          selected={isoDate === day}
          current={isCurrent}
          onClick={() => onDayClick(isoDate)}
        >
          <div>{name}</div>
          <div>{dayOfMonth}</div>
        </Styles.Day>
      ))}
      <Styles.ChevronContainer role='button' onClick={() => onWeekChange(currentSelectedWeek.next)}>
        <Styles.Chevron />
      </Styles.ChevronContainer>
    </Styles.Container>
  )
}

DatePicker.propTypes = {
  day: PropTypes.string,
  onDayClick: PropTypes.func,
  onWeekChange: PropTypes.func,
}

const Styles = {
  Container: styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--neutrals200);
    border-top: 1px solid var(--neutrals400);
    border-bottom: 1px solid var(--neutrals500);
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
  `,
  Day: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    min-width: 19px;
    color: ${({ current }) => (current ? '#000000' : 'var(--neutrals600)')};
    border-bottom: 2px solid ${({ selected }) => (selected ? 'var(--primary400)' : '#ffffff')};
    transition: all 0.15s ease-in-out;
    &:hover > div {
      color: var(--neutrals700);
    }
    &:focus,
    &:active {
      color: ${({ current }) => (current ? '#000000' : 'var(--neutrals600)')};
    }
    & > div {
      padding-top: 8px;
    }
  `,
  ChevronContainer: styled.div`
    position: absolute;
    bottom: 16px;
    ${({ left }) =>
      left
        ? css`
            left: 16px;
          `
        : css`
            right: 16px;
          `}
  `,
  Chevron: styled(Icon.Chevron)`
    transform: ${({ left }) => (left ? 'rotate(90deg)' : 'rotate(270deg)')};
    width: 11px;
    height: 8px;
    display: inline;
  `,
}
