import useSwr from 'swr'
import { getStats } from 'src/models/obeClassViews'

const STATS_KEY = '/api/v3/obe_class_views/stats'

export const useStats = () =>
  useSwr(STATS_KEY, getStats, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  })
