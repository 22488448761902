import { mixin } from 'src/styles'
import { Icon } from 'src/components/Icon'

interface Props {
  hourMinute: string | number
  isCompact: boolean
  isEncore: boolean
  meridiem: string | number
  isCompleted: boolean
}

export const TimeAndType = ({
  hourMinute,
  isCompact,
  isEncore,
  meridiem,
  isCompleted = false,
}: Props) => (
  <Container isCompact={isCompact}>
    {isCompleted && (
      <Completed>
        <Icon.CheckmarkCircle />
      </Completed>
    )}
    {hourMinute} <Meridiem>{meridiem}</Meridiem>
    {isEncore && <EventType isCompact>Encore</EventType>}
  </Container>
)

const Container = styled.div<{ isCompact: boolean }>`
  ${mixin.flexStatic}
  font-size: 23px;
  line-height: 18px;
  width: 85px;

  ${({ isCompact }) =>
    isCompact &&
    css`
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 18px;
      margin-top: 5px;
      width: 70px;
    `}
`
const Meridiem = styled.span`
  font-size: 12px;
`
const EventType = styled.div<{ isCompact: boolean }>`
  color: var(--persianBlue);
  font-size: 11px;
  letter-spacing: 0.85px;
  margin-top: 0.6rem;
  text-transform: uppercase;

  ${({ isCompact }) =>
    isCompact &&
    css`
      margin-top: 0.3rem;
      color: var(--neutrals700);
      font-weight: 200;
      font-size: 14px;
    `}
`
const Completed = styled.div`
  margin-bottom: 6px;
`
