import { AlertStyles } from './styles'

interface Props {
  message: string
  alertColor?: string
  isResubscribing?: boolean
}

export const SuccessMessage = ({ message, alertColor = 'var(--persianBlue)' }: Props) => {
  return <AlertStyles.Success alertColor={alertColor}>{message}</AlertStyles.Success>
}
