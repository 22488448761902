export const useScrollView = (ref) => {
  const containerRef = React.useRef(null)
  const itemsMapRef = React.useRef(null)
  const initialAutoScrollRef = React.useRef(false)

  const createItemRefCallback = (itemKey, isCurrent) => (node) => {
    if (!itemsMapRef.current) {
      itemsMapRef.current = new Map()
    }
    if (node) {
      itemsMapRef.current.set(itemKey, node)
      if (isCurrent && !initialAutoScrollRef.current && containerRef.current) {
        containerRef.current?.scrollTo({
          left: 0,
          top: (node?.offsetTop ?? 0) - containerRef.current.offsetTop,
          behavior: 'smooth',
        })
        initialAutoScrollRef.current = true
      }
    } else {
      itemsMapRef.current.delete(itemKey)
    }
  }

  React.useImperativeHandle(
    ref,
    () => {
      return {
        scrollTo(itemKey) {
          const node = itemsMapRef.current?.get(itemKey)
          if (containerRef.current) {
            containerRef.current.scrollTo({
              left: 0,
              top: (node?.offsetTop ?? 0) - containerRef.current.offsetTop,
              behavior: 'smooth',
            })
          }
        },
        resetInitialAutoScroll() {
          itemsMapRef.current = new Map()
          initialAutoScrollRef.current = false
        },
      }
    },
    [],
  )

  return { containerRef, createItemRefCallback }
}
