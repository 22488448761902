import { Link } from 'src/components/Link'
import { button } from 'src/styles'

export const MyScheduleEmptyState = () => (
  <Styles.Container>
    <Styles.Description>
      Add classes to your schedule now so you never miss a sweat sesh!
    </Styles.Description>
    <Styles.Button to='/videos'>Find a class</Styles.Button>
  </Styles.Container>
)

const Styles = {
  Container: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    text-align: center;
  `,
  Description: styled.div`
    padding: 30px 50px 12px 50px;
    font-size: 16px;
    font-weight: 300;
  `,
  Button: styled(Link)`
    ${button.secondary}
    margin: auto 40px;
    padding: 8px 0;
    width: 170px;
    font-size: 16px;
  `,
}
