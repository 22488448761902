import PropTypes from 'prop-types'
import { breakpoint } from 'src/styles'

export const SchedulesTabs = ({ labels, selectedIndex = 0, onTabClick }) => (
  <Styles.Container>
    {labels.map((label, i) => (
      <Styles.Tab
        key={`tab${i}`}
        role='button'
        active={i === (selectedIndex ?? 0)}
        onClick={() => onTabClick && onTabClick(i)}
      >
        <span>{label}</span>
      </Styles.Tab>
    ))}
  </Styles.Container>
)

SchedulesTabs.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedIndex: PropTypes.number,
  onTabClick: PropTypes.func,
}

const Styles = {
  Container: styled.div`
    display: flex;
    flex-direction: row;
  `,
  Tab: styled.div`
    flex: 1 1 0%;
    display: flex;
    height: 48px;
    justify-content: center;
    background: var(--neutrals100);
    letter-spacing: 0.5px;
    font-size: 20px;
    line-height: 28px;
    padding-top: 8px;
    transition: all 0.15s ease-in-out;

    ${({ active }) =>
      active &&
      css`
        font-weight: 400;
        color: #1c1b1f;
        border-bottom: 2px solid var(--primary400);
      `}

    ${({ active }) =>
      !active &&
      css`
        font-weight: 300;
        color: #49454f;
        border-bottom: 2px solid var(--neutrals100);
      `}

    ${breakpoint.smallAndDown} {
      font-size: 16px;
      line-height: 18px;
      padding-top: 12px;
    }
  `,
}
