import moment from 'moment'
import { format, parse } from 'date-fns'
import { getWeekData } from 'src/utils/Date'

const getDayTitle = (isoDate) =>
  moment(isoDate).isSame(new Date(), 'day')
    ? `Today, ${moment(isoDate).format('MMMM Do')}`
    : moment(isoDate).format('dddd, MMMM Do')

const emptyArray = []

const getDayType = (events) => {
  let dayType = 'empty'

  if (events.length > 0) {
    dayType = 'class'
  }

  return dayType
}

export const getScheduleDays = (selectedDay, scheduleData) => {
  if (!scheduleData) {
    return emptyArray
  }
  const { days } = getWeekData(selectedDay)
  const twoWeeksFromNow = moment().add(14, 'days')
  const scheduleDays = days.map((day) => {
    const dateKey = day.isoDate.substring(0, 10)
    const dayInfo = {
      ...day,
      dayTitle: getDayTitle(day.isoDate),
    }
    if (!scheduleData[dateKey] || moment(day.isoDate).isAfter(twoWeeksFromNow, 'day')) {
      return { ...dayInfo, events: [] }
    }
    const scheduledEvents = scheduleData?.[dateKey]?.scheduledEvents ?? []
    const completedClassesIds = (scheduleData?.[dateKey]?.completedClasses ?? []).map(
      ({ id }) => id,
    )

    const events =
      scheduledEvents
        .map((scheduled) =>
          scheduled.classes?.map((event) => ({
            ...event,
            isCompleted: completedClassesIds.includes(event.id),
            classPartyId: scheduled?.classPartyId,
          })),
        )
        .flat() ?? []

    return {
      ...dayInfo,
      dayType: getDayType(events),
      events,
    }
  })

  if (scheduleDays.reduce((prev, { events }) => prev + events.length, 0) === 0) {
    return []
  }

  return scheduleDays
}

export const removeEventFromMySchedule = (scheduleData, eventId) =>
  Object.keys(scheduleData).reduce(
    (prev, key) => ({
      ...prev,
      [key]: {
        ...scheduleData[key],
        scheduledEvents: scheduleData[key].scheduledEvents?.map((scheduled) => ({
          ...scheduled,
          classes: scheduled.classes.filter(({ id }) => id !== eventId),
        })),
      },
    }),
    {},
  )

export const getCalendarModifiers = (scheduleData, selectedDay) => {
  if (!scheduleData) {
    return {
      completed: [],
      scheduled: [],
      pastNotCompleted: { before: new Date() },
      scheduledSelected: [],
      completedSelected: [],
    }
  }

  const today = format(new Date(), 'yyyy-MM-dd')
  const pastCompleted = []

  return Object.entries(scheduleData).reduce(
    (prev, [dateKey, data]) => {
      const current = { ...prev }
      const date = parse(dateKey, 'yyyy-MM-dd', new Date())
      if (dateKey < today && data?.completedClasses?.length) {
        if (dateKey === selectedDay) {
          current.completedSelected = [date]
        } else {
          current.completed = current.completed.concat(date)
        }

        pastCompleted.push(dateKey)
      }
      if (
        dateKey >= today &&
        data?.scheduledEvents?.length &&
        data?.scheduledEvents.some((event) => !!event?.classes?.length)
      ) {
        if (dateKey > today && dateKey === selectedDay) {
          current.scheduledSelected = [date]
        } else {
          current.scheduled = current.scheduled.concat(date)
        }
      }

      return current
    },
    {
      completed: [],
      scheduled: [],
      scheduledSelected: [],
      completedSelected: [],
      pastNotCompleted: (d) => {
        const dateKey = format(d, 'yyyy-MM-dd')
        return dateKey < today && !pastCompleted.includes(dateKey)
      },
    },
  )
}
