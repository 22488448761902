import debounce from 'lodash.debounce'
import { useEffect, useRef, useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'
import { isAbort } from 'src/httpClient'

import { getUsernameAvailability } from 'src/models/profile'

export const useUsernameAvailability = (username: string, initialUsername: string) => {
  const [isAvailable, setIsAvailable] = useState<boolean>()

  const asyncUsernameAvailability = useAsyncCallback(async (username, signal) => {
    try {
      const result = await getUsernameAvailability(username, signal)
      setIsAvailable(result.data?.available)
    } catch (e) {
      if (!isAbort(e as Error)) setIsAvailable(undefined)
    }
  })

  const debouncedUsernameAvailability = useRef(
    debounce((username, signal) => asyncUsernameAvailability.execute(username, signal), 750),
  ).current

  useEffect(() => {
    const controller = new AbortController()

    setIsAvailable(undefined)

    if (initialUsername !== username && username.length > 2) {
      debouncedUsernameAvailability(username, controller.signal)
    }

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUsernameAvailability, username])

  return {
    isAvailable,
    isLoading: asyncUsernameAvailability.loading,
  }
}
