import useSWRImmutable from 'swr/immutable'

import { MemberService } from 'src/services'

export const useMySchedule = (startDate: string) =>
  useSWRImmutable(
    startDate !== null
      ? MemberService.getSWRKey({
          method: MemberService.methods.GetMemberSchedule,
          params: { start_date: startDate },
        })
      : null,
    () => MemberService.getMemberSchedule({ start_date: startDate }),
  )
