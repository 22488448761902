import { Event } from 'src/components/Event'
import { Render } from 'src/components/Event/renderStyles'
import { Spinner } from 'src/components/Spinner'
import { NoClassesTout } from 'src/views/ScheduleView/NoClassesTout'
import { useScrollView } from 'src/hooks/useScrollView'
import { MyScheduleEmptyState } from './MyScheduleEmptyState'
import { ScheduleDayHeader } from './ScheduleDayHeader'

export const SchedulesScrollView = React.forwardRef(
  ({ days, isLoading, onCalendarChange }, ref) => {
    const { containerRef, createItemRefCallback } = useScrollView(ref)

    const renderDayContent = React.useCallback(
      (event) => {
        const key = `my-${event.classPartyId ?? event.id}`

        return (
          <Styles.EventContainer key={key}>
            <Event
              event={event}
              trackingContext='schedule'
              hasMultiInstructors={false}
              isCompact
              isMySchedule
              styles={Render.Event}
              onCalendarChange={onCalendarChange}
            />
          </Styles.EventContainer>
        )
      },
      [onCalendarChange],
    )

    if (isLoading) {
      return (
        <Styles.SpinnerContainer>
          <Spinner />
        </Styles.SpinnerContainer>
      )
    }

    if (!days?.length) {
      return <MyScheduleEmptyState />
    }

    return (
      <Styles.OuterContainer>
        <Styles.Container ref={containerRef}>
          {days?.map(({ isoDate, dayTitle, dayType, events, isSelected }) => (
            <div key={isoDate} ref={createItemRefCallback(isoDate, isSelected)}>
              <ScheduleDayHeader dayTitle={dayTitle} dayType={dayType} />
              {events?.map((event) => renderDayContent(event))}
            </div>
          ))}
        </Styles.Container>
      </Styles.OuterContainer>
    )
  },
)

SchedulesScrollView.propTypes = {
  days: PropTypes.array,
  isLiveSchedule: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCalendarChange: PropTypes.func,
}

const Styles = {
  OuterContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: ${({ scrollable }) => (scrollable ? 'scroll' : 'hidden')};
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  Container: styled.div`
    flex: 1;
    min-height: 0;
    overflow-y: scroll;

    & > div:first-of-type > div {
      border-top: none;
    }
  `,
  EventContainer: styled.div``,
  LiveEmptyState: styled(NoClassesTout)`
    padding: 30px 30px 0px;

    & > div {
      font-size: 16px;
    }

    & > p {
      font-size: 12px;
    }
  `,
  SpinnerContainer: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  `,
}
