import { CSSProperties } from 'react'

export const AlertStyles = {
  Error: styled.div<{ hasTopMargin?: boolean }>`
    color: white;
    background: #e5341d;
    border-radius: 4px;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 10px 15px;
    margin-bottom: 15px;

    ${({ hasTopMargin }) =>
      hasTopMargin &&
      css`
        margin-top: 15px;
      `}
  `,
  Success: styled.div<{ alertColor: CSSProperties['backgroundColor']; hasTopMargin?: boolean }>`
    color: white;
    background: ${(props) => props.alertColor};
    border-radius: 4px;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 10px 15px;
    margin-bottom: 15px;

    ${({ hasTopMargin }) =>
      hasTopMargin &&
      css`
        margin-top: 15px;
      `}
  `,
}
