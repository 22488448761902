import moment from 'moment'

moment.suppressDeprecationWarnings = true

export const isSameDate = (from, to) =>
  moment(from).format('YYYY MM DD') === moment(to).format('YYYY MM DD')

export const toDateParam = (date) =>
  moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null

export const isMonthAgo = (startDate) => {
  if (moment(startDate).isValid) {
    return moment(new Date()).diff(startDate, 'days')
  }
}

export const getFormattedDateHeader = (sentAt) => moment.unix(sentAt).format('dddd MMMM DD, YYYY')

export const showDateHeader = (idx, messageList, message) =>
  idx === 0 ||
  moment.unix(messageList?.[idx - 1]?.sentAt).format('LL') <
    moment.unix(message?.sentAt).format('LL')

const DAY_NAMES = ['M', 'T', 'W', 'T', 'F', 'S', 'S']

export const getWeekStart = (dateString) => {
  const selectedDay = moment(dateString)

  if (!selectedDay.isValid()) {
    return null
  }

  return selectedDay.startOf('isoWeek').format('YYYY-MM-DD')
}

export const getWeekData = (dateString) => {
  const selectedDay = moment(dateString)

  if (!selectedDay.isValid()) {
    return null
  }

  const startOfWeek = selectedDay.clone().startOf('isoWeek')
  const endOfWeek = selectedDay.clone().endOf('isoWeek')

  const currentDate = new Date()

  const days = []
  let day = startOfWeek.clone()

  while (day <= endOfWeek) {
    days.push({
      isoDate: day.format('YYYY-MM-DD'),
      dayOfMonth: day.date(),
      name: DAY_NAMES[day.isoWeekday() - 1] ?? '',
      isCurrent: day.isSame(currentDate, 'day'),
      isSelected: day.isSame(selectedDay, 'day'),
    })
    day = day.clone().add(1, 'day')
  }

  return {
    days,
    prev: startOfWeek.clone().subtract(1, 'week').format('YYYY-MM-DD'),
    next: startOfWeek.add(1, 'week').format('YYYY-MM-DD'),
  }
}
