// 'empty' | 'class' | 'rest-day-empty' | 'rest-day-with-link' | 'rest-day-with-class' | 'no-class-but-has-link'
export const ScheduleDayHeader = ({ dayTitle, dayType }) => {
  switch (dayType) {
    case 'empty':
      return (
        <Styles.DayContainer>
          <div>{dayTitle}</div>
          <Styles.NothingScheduled>Nothing scheduled</Styles.NothingScheduled>
        </Styles.DayContainer>
      )
    case 'class':
    default:
      return (
        <Styles.DayContainer>
          <div>{dayTitle}</div>
        </Styles.DayContainer>
      )
  }
}

const Styles = {
  DayContainer: styled.div`
    display: flex;
    align-items: center;
    color: var(--neutrals800);
    background: var(--neutrals300);
    border-top: 1px solid #d0d0d0;
    font-weight: 300;
    padding: 16px;

    & > div {
      flex: 1;
    }
  `,
  NothingScheduled: styled.div`
    font-size: 14px;
    color: var(--neutrals700);
    text-align: right;
  `,
  RestDay: styled.div`
    font-size: 14px;
    color: var(--neutrals100);
    text-align: right;
  `,
}
