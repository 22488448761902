import { breakpoint, mixin } from 'src/styles'
import { Image } from 'src/components/Display/Image'
import { Play } from 'src/components/Display/Play'
import { Link } from 'src/components/Link'
import { UserAction } from 'src/components/UserAction'
import { Calendar } from 'src/views/Video/VideoDetails/Calendar'

export const defaultStyles = {
  OuterContainer: styled.div`
    :not(:last-of-type) {
      border-bottom: 1px solid #dee2e6;
    }

    ${breakpoint.smallAndDown} {
      display: flex;
      flex-direction: column;
      padding: 1.5rem 0;
    }
  `,
  Container: styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 300;
    padding-left: 0.7rem;

    ${breakpoint.mediumAndUp} {
      align-items: center;
      padding: 1.5rem 2rem;
    }
  `,
  AvatarContainer: styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 90px;
    padding: 0 0 0 6.5rem;

    ${breakpoint.largeAndUp} {
      padding: 0 0 0 8.5rem;

      ${({ isProfileSchedule }) =>
        isProfileSchedule &&
        css`
          padding: 0 0 0 5.5rem;
          margin-left: 10px;
        `}
    }
  `,
  Avatar: styled(Image)`
    flex-grow: 0;
    flex-shrink: 0;
    align-items: flex-end;
    margin: 0 1rem 0 0.5rem;
    width: 48px;

    ${breakpoint.largeAndUp} {
      margin: 0 1.875rem;
      width: 61px;
    }
  `,
  Avatars: styled(Image)`
    flex-grow: 0;
    flex-shrink: 0;
    width: 48px;
    align-items: flex-end;
    justify-content: flex-end;

    ${({ numInstructors }) =>
      numInstructors === 1 &&
      css`
        margin: 0 20px 0 0.5rem;
      `}
    ${({ numInstructors }) =>
      numInstructors === 2 &&
      css`
        margin: 0 20px 0 -2.3rem;
        width: 42px;
      `}
    ${({ numInstructors }) =>
      numInstructors === 3 &&
      css`
        margin: 0 20px 0 -2.3rem;
        width: 46px;
      `}
    ${({ eventsList, numInstructors }) =>
      eventsList &&
      numInstructors === 3 &&
      css`
        margin: 0 20px 0 -2.3rem;
        width: 40px;
      `}

    ${breakpoint.largeAndUp} {
      ${({ numInstructors }) =>
        (numInstructors === 2 || numInstructors === 1) &&
        css`
          width: 61px;
        `}

      ${({ numInstructors, isProfileSchedule }) =>
        numInstructors === 2 &&
        isProfileSchedule &&
        css`
          width: 42px;
        `}
    }
  `,
  Details: styled.div`
    flex-grow: 1;
    margin-top: 5px;

    ${breakpoint.largeAndUp} {
      ${mixin.flexCenterVertical}
      ${mixin.spaceChildrenHorizontal('3rem')}
      margin-top: 0;
      padding-right: 0;
    }
  `,
  InnerDetailsOne: styled.div`
    ${breakpoint.mediumAndUp} {
      flex-basis: 23%;
      padding-bottom: 0;
    }
  `,
  Title: styled.div`
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.5px;

    ${breakpoint.mediumAndUp} {
      font-size: 23px;
      line-height: 23px;
    }
  `,
  Instructors: styled.div`
    ${mixin.spaceChildrenVertical('.3rem')}
    margin-top: .6rem;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.85px;
    text-transform: uppercase;

    > a {
      display: block;
    }

    ${breakpoint.mediumAndDown} {
      margin-top: 0.4rem;
    }
  `,
  HighlightContainer: styled.div`
    ${mixin.spaceChildrenVertical('.3rem')}
    margin-top: 1rem;

    ${breakpoint.mediumAndDown} {
      margin-top: 0.6rem;
    }
  `,
  Highlight: styled.div`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.85px;
  `,
  InnerDetailsTwo: styled.div`
    ${mixin.spaceChildrenVertical('.4rem')}
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: lowercase;

    ${breakpoint.mediumAndDown} {
      margin-top: 1rem;
    }

    ${breakpoint.largeAndUp} {
      text-align: center;
      flex-basis: 24%;
    }
  `,
  InnerDetailsThree: styled.div`
    ${mixin.flexCenterHorizontal}
    flex-wrap: wrap;
    font-size: 24px;
    line-height: 24px;
    font-weight: 200;
    letter-spacing: 0.015em;
    color: #ff0000;
    text-transform: uppercase;

    ${breakpoint.mediumAndDown} {
      position: absolute;
      top: 63px;
      right: 12px;
      font-size: 20px;
    }

    ${breakpoint.largeAndUp} {
      flex-grow: 1;
      line-height: 36px;
      margin-right: 4rem !important;
    }
  `,
  FriendsContainer: styled.div`
    ${mixin.flexCenterHorizontal}
    flex-wrap: wrap;

    ${breakpoint.mediumAndDown} {
      display: flex;
      justify-content: flex-start;
      width: calc(100% + 55px);
      margin-top: 1rem;
      text-transform: none;
    }

    ${breakpoint.largeAndUp} {
      flex-grow: 1;
      margin-right: 3.5rem !important;
    }
  `,
  ToggleCalendar: styled(Calendar)`
    height: 28px;
    width: 28px;
    margin-left: 17px;
    margin-bottom: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    ${breakpoint.smallAndDown} {
      margin-bottom: 10px;
      margin-top: 0.5rem;
    }

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        opacity: 1;
      `}
  `,
  Play: styled(Play)`
    height: 28px;
    width: 28px;
    margin-left: 17px;
    margin-bottom: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    ${breakpoint.smallAndDown} {
      margin-top: 0.5rem;
    }
  `,
  PartyIcon: styled(UserAction)`
    height: 28px;
    width: 28px;
    margin: 0 0 0px 17px;
    outline: none;
    justify-content: flex-end;
    align-items: flex-end;
    ${breakpoint.smallAndDown} {
      margin: 0.5rem 0 0 0;
    }
  `,
  PartyTitle: styled(Link)`
    font-size: 14px;
  `,
  IconContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    ${({ isProfileSchedule }) =>
      isProfileSchedule &&
      css`
        ${breakpoint.largeAndUp} {
          padding: 0.75rem;
        }

        flex-direction: column-reverse;
      `}

    ${breakpoint.smallAndDown} {
      display: flex;
      flex-direction: column-reverse;
      padding-right: 0.5rem;
      justify-content: center;
      align-content: center;
      align-items: flex-end;
      flex-wrap: wrap;

      ${({ eventsList }) =>
        eventsList === true &&
        css`
          margin-right: 0.7rem;
        `}
    }
  `,
}
