import Image from 'next/legacy/image'
import { widths, cloudflareLoader } from 'src/utils/image'

const imageUrl = cloudflareLoader({ src: '/no-classes-381x378.webp', width: widths.card })

interface NoClassesToutProps {
  width?: number
  height?: number
  className?: string
}

export const NoClassesTout = ({ width = 381, height = 378, className }: NoClassesToutProps) => {
  return (
    <Styles.Container className={className}>
      <Image alt='No classes today' width={width} height={height} src={imageUrl} unoptimized />
      <Styles.Title>We&apos;re out of the Box today!</Styles.Title>
      <p>
        Need your obé fix? Head to our on-demand library where you can go all in, full out 24/7. See
        you in class!
      </p>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    letter-spacing: 0.005em;
    line-height: 1.3;
    margin: 0 auto;
    max-width: 500px;
    padding: 60px 20px 120px 20px;
    text-align: center;

    img {
      max-width: 127px;
    }
  `,
  Title: styled.div`
    font-size: 32px;
    letter-spacing: 0.005em;
    line-height: 1.26;
    font-weight: 300;
    padding: 32px 0 16px;
  `,
}
